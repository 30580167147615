import React from 'react'
import styles from './Lazy.module.css'
import logo from 'assets/img/SAHCO/sifax-sahco-logo-brand-1.png'

const Lazy = () => {

  return (

    <div className={styles.container}>
      <img src={logo} alt='SAHCO Loader'/>
    </div>
  )
}

export default Lazy