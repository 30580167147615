import { Navigate } from "react-router-dom";
import useContextGetter from "hooks/useContextGetter";

function ProtectedRoute({ children, role }) {
  const { user } = useContextGetter();

  function sessionGet(key) {
    let stringValue = sessionStorage.getItem(key)
    if (stringValue !== null) {
      let expirationDate = new Date(stringValue)
      if(expirationDate > new Date()) {
        return true
      } else {
        sessionStorage.removeItem(key)
        sessionStorage.clear()
      }
    }
    return null
  }
  const date = sessionGet('expirationDate')

  if (!date) {
    return <Navigate to="/login" />;
  } else if (!role.includes(user?.role)) {
    return <Navigate to='/' replace/>
  }

  return children;
}

export default ProtectedRoute;