import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack';
import './App.css';
import AppRouter from './routes';

function App() {
  return (
    <BrowserRouter>
      <SnackbarProvider>
        <AppRouter/>
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;
