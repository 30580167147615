import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Lazy from "./layout/Lazy";
import AppState from "./store/AppState";
import ProtectedRoute from "./utils/ProtectedRoute";

const Login = lazy(() => import("./pages/Login/Login"));
const Home = lazy(() => import("./pages/Home/Home"));
const AddDepartment = lazy(() => import("./pages/AddDepartment/AddDepartment"));
const AdminDashboard = lazy(() => import("./pages/AdminDashboard/AdminDasboard"));
const Request = lazy(() => import("./pages/Forgot/Request"));
const Verify = lazy(() => import("./pages/Forgot/Verify"));
const UserBudget = lazy(() => import("./pages/UserBudget/UserBudget"));
const UserBudgetByYear = lazy(() => import("./pages/UserBudget/UserBudgetByYear"));
const MembersList = lazy(() => import("./pages/MembersList/MembersList"));
const AllBudgets = lazy(() => import("./pages/AllBudget/AllBudget"));
const BudgetByYear = lazy(() => import("./pages/AllBudget/BudgetByYear"));
const DeptBudget = lazy(() => import("./pages/DeptBudget/DeptBudget"));
const DeptBudgetByYear = lazy(() => import("./pages/DeptBudget/DeptBudgetByYear"));
const Profile = lazy(() => import("./pages/Profile/Profile"));
const Accounts = lazy(() => import("./pages/Accounts/Accounts"));
const Chart = lazy(() => import("./pages/Charts/Chart"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound"));

const AppRouter = () => {
  return (
    <Suspense fallback={<Lazy />}>
      <AppState>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/request_otp" element={<Request />} />
          <Route path="/verify_otp" element={<Verify />} />
          <Route
            path="/add_department"
            element={
              <ProtectedRoute role={["ADMIN"]}>
                <AddDepartment />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute role={["ADMIN", "USER"]}>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/budget"
            element={
              <ProtectedRoute role={["USER"]}>
                <UserBudget />
              </ProtectedRoute>
            }
          />
          <Route
            path="/budget/:year"
            element={
              <ProtectedRoute role={["USER"]}>
                <UserBudgetByYear />
              </ProtectedRoute>
            }
          />
          <Route
            path="/members"
            element={
              <ProtectedRoute role={["ADMIN"]}>
                <MembersList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/all_budget"
            element={
              <ProtectedRoute role={["ADMIN"]}>
                <AllBudgets />
              </ProtectedRoute>
            }
          />
          <Route
            path="/all_budget/:year"
            element={
              <ProtectedRoute role={["ADMIN"]}>
                <BudgetByYear />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dept/:dept"
            element={
              <ProtectedRoute role={["ADMIN"]}>
                <DeptBudget />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dept/:dept/:year"
            element={
              <ProtectedRoute role={["ADMIN"]}>
                <DeptBudgetByYear />
              </ProtectedRoute>
            }
          />
          <Route
            path="/accounts"
            element={
              <ProtectedRoute role={["ADMIN"]}>
                <Accounts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chart"
            element={
              <ProtectedRoute role={["ADMIN"]}>
                <Chart />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute role={["ADMIN", "USER"]}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AppState>
    </Suspense>
  );
};

export default AppRouter;
